import { Typography,Card} from 'antd';
import React, { Component} from 'react';
import black from '../../contains/black.png'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
const { Paragraph,Title } = Typography;



export default class Connect extends Component{
    constructor(props) {
        super(props)
        this.state = { ellipsis: true }
      }
      setEllipsis = (isEllipsis) => {
        this.setState({ellipsis:isEllipsis});
      }
    render() { const ellipsis=this.state.ellipsis;
        return(
      <div>
     <Typography>
    <Title>联系我们</Title>
      </Typography>
  <div className="site-card-wrapper">
      <Card
  >
      <p style={{ textAlign: 'center' }}>联系方式</p>
      <p style={{ textAlign: 'center' }}><MailOutlined /> Consult@Feigenbaum.cn</p>
      {/* <p style={{ textAlign: 'center' }}><PhoneOutlined /> +86 196 021 99191</p> */}
   
        </Card>
          </div>
          </div>
      )
    }
}