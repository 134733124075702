import { Typography } from 'antd';
import React, { Component} from 'react';
import styles from './style.module.css';
const { Paragraph, Text,Title } = Typography;
const message =  (
    <>
        菲格勃姆同时提供猎头及人才中介、多渠道招聘、人才（劳务）派遣、人才测评、培训、管理服务、信息调查和数据处理服务，以及人力资源管理咨询等服务活动，助力您的企业更上一层楼。<br />
        1、招聘方面：猎头、招聘外包（RPO）、劳务派遣。<br />
        2、薪酬福利保险方面：代办，代发。<br />
        3、培训方面：外训、内训。<br />
        4、人力资源规划方面：咨询、建议、方案。<br />
        欢迎广大客户来电来函咨询。 <br />
      </>
 );

export default class Hr extends Component{
    constructor(props) {
        super(props)
        this.state = { ellipsis: true }
      }
      setEllipsis = (isEllipsis) => {
        this.setState({ellipsis:isEllipsis});
      }
    render() {
        return(
      <div className={styles['backimage']}>
     <Typography>
    <Title>人事服务</Title>
    <Paragraph style={{ padding: '100px 0px' }}>
    {message}
    </Paragraph>
    </Typography>
          </div>
      )
    }
}