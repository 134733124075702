import { Typography,Card} from 'antd';
import React, { Component} from 'react';
import black from '../../contains/black.png'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import styles from './style.module.css';
const { Paragraph,Title } = Typography;



export default class First extends Component{
    constructor(props) {
        super(props)
        this.state = { ellipsis: true }
      }
      setEllipsis = (isEllipsis) => {
        this.setState({ellipsis:isEllipsis});
      }
    render() { const ellipsis=this.state.ellipsis;
        return(
      <div>
     <Typography >
      <Paragraph  style={{fontSize: '20px',fontFamily:'Microsoft Yahei'}} level={2} ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: 'more' } : false}>
          菲格勃姆(Feigenbaum)创立于2020年，是一家创新引领提升的战略管理咨询公司。 
      依托于同济大学汽车学院的科研力量和其影响力，菲格勃姆比任何其他的企业都更了解中国中小企业
      管理层和员工的行为和态度。根植于全面质量战略咨询、领导力提升和人事服务三大核心领域，菲格
      勃姆向领导层和企业提供深入分析和专业见解，以帮助他们解决最紧迫的问题。后疫情时代，
      菲格勃姆能够帮助您的企业更好的进行运营驱动增长。
      </Paragraph>
      </Typography>
  <div className="site-card-wrapper">
      <Card
    hoverable
    style={{ width: "auto" }}
    cover={<img alt="example" src={black} />}
  >
      <p style={{ textAlign: 'center',fontFamily:'Microsoft Yahei' }}>联系方式</p>
      <p style={{ textAlign: 'center',fontFamily:'Microsoft Yahei' }}><MailOutlined /> Consult@Feigenbaum.cn</p>
      {/* <p style={{ textAlign: 'center',fontFamily:'Microsoft Yahei'}}><PhoneOutlined /> +86 196 021 99191</p> */}
   
        </Card>
          </div>
          </div>
      )
    }
}