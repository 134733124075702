import { Typography } from 'antd';
import React, { Component} from 'react';
const { Paragraph, Text,Title } = Typography;

const message =  (
  <>
        菲格勃姆(Feigenbaum)创立于2020年，是一家<Text type="danger">创新引领提升的战略管理</Text>咨询公司。 <br />
        依托于同济大学汽车学院的科研力量和其影响力，菲格勃姆比任何其他的企业都更了解中国中小企业管理层和员工的行为和态度。<br />
        根植于全面质量战略咨询、领导力提升和人事服务三大核心领域，菲格勃姆向领导层和企业提供深入分析和专业见解，<br />
        以帮助他们解决最紧迫的问题。后疫情时代，菲格勃姆能够帮助您的企业更好的进行<Text type="danger">运营驱动增长。</Text><br />
  </>
)
export default class About extends Component{
    constructor(props) {
        super(props)
        this.state = { ellipsis: true }
      }
      setEllipsis = (isEllipsis) => {
        this.setState({ellipsis:isEllipsis});
      }
    render() {
        return(
      <div>
     <Typography>
    <Title>关于我们</Title>
    <Paragraph>
            菲格勃姆(Feigenbaum)创立于2020年，是一家<Text type="danger">创新引领提升的战略管理</Text>咨询公司。 
        依托于同济大学汽车学院的科研力量和其影响力，菲格勃姆比任何其他的企业都更了解中国中小企业管理层和员工的行为和态度。
        根植于全面质量战略咨询、领导力提升和人事服务三大核心领域，菲格勃姆向领导层和企业提供深入分析和专业见解，
        以帮助他们解决最紧迫的问题。后疫情时代，菲格勃姆能够帮助您的企业更好的进行<Text type="danger">运营驱动增长。</Text>
        </Paragraph>

        <Paragraph>
        我们的多位专家都是各领域翘楚，全部由知名院校的硕士和博士毕业生组成。他们对终端客户的需求了然于胸，
        且专攻全面质量管理、领导力提升和基于才干的测评，以帮助企业建立新形象和更深层次的维护与顾客的客户的关系。
    </Paragraph>
    </Typography>
          </div>
      )
    }
}