import { Typography,Card,Row,Col } from 'antd';
import React, { Component} from 'react';
import styles from './style.module.css';
import black3 from '../../contains/black3.png'
const { Paragraph, Text,Title } = Typography;
const {Meta} = Card
const message=(
  <>
    <Paragraph style={{ color:'white', font: 'itelic small-caps bold 50px "宋体" serif'}}>
    全面质量战略咨询：为终端客户量身定制的有机增长服务模式
    </Paragraph>
    <Paragraph style={{ color:'white' }}>
      菲格勃姆的战略咨询旨在建立顾客一体化的质量体系，培养与顾客、制造商和供应商之间的关系；同时，通过财务语言，引入质量成本的概念，从而为顾客带来有机增长。
    </Paragraph>
    <Paragraph style={{ color:'white'}}>
    从传统意义上说，质量与产品的可靠度、适用度和完善度以及服务的响应度和友好度是相关的。生产能力关心的是资源的有效利用，它能够降低成本，使得价格具有吸引力。创新则是市场供应新的、可能是独一无二的利润的程度。围绕改进机构和改变行动的争论推动了质量、生产能力和创新三方面的选择。怎样更好地将这三种方法与为企业的参股人提供价值这个目标紧密联系起来，是菲格勃姆成立的目标和宗旨。
    </Paragraph>
    </>
)


export default class Consult extends Component{
    constructor(props) {
        super(props)
        this.state = { ellipsis: true }
      }
      setEllipsis = (isEllipsis) => {
        this.setState({ellipsis:isEllipsis});
      }
    render() {
        return(
      <div className={styles['back2image']}>
     <Typography >
    <Title style={{ color:'white' }}> 战略咨询</Title>
    {/* <Card
    hoverable
    style={{ width: "auto" }}
        cover={<img alt="example" src={black2} />}>*/}
     <div className={styles["site-card-wrapper"]}> 
<Row justify="center">
  <Col span={10}>
  </Col>
  <Col span={8}>
    <Row></Row>
    <Row style={{margin:'10px 0px'}} >{message}</Row>
  </Col>
</Row>
</div>
    {/* </Card> */}
    
    </Typography>
          </div>
      )
    }
}