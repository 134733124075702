import { Typography } from 'antd';
import React, { Component} from 'react';
import styles from './style.module.css';
const { Paragraph, Text,Title } = Typography;


export default class Leader extends Component{
    constructor(props) {
        super(props)
        this.state = { ellipsis: true }
      }
      setEllipsis = (isEllipsis) => {
        this.setState({ellipsis:isEllipsis});
      }
    render() {
        return(
      <div className={styles['backimage']}>
     <Typography>
    <Title>领导力提升</Title>
    <Paragraph style={{ padding: '100px 0px' }}>
      作为企业提高营收和增强竞争力的重心，一直是菲格勃姆所擅长的提升方向。一个企业的生存或者死亡，往往取决于企业领导者战斗意志的存亡。
      领导者只要战斗意志不息，其实他总能再募到资源，再找机会，再干一把
    </Paragraph>
    </Typography>
          </div>
      )
    }
}