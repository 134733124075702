import { Typography,Card,Col, Row } from 'antd';
import React, { Component} from 'react';
import styles from './style.module.css';
import top1 from '../../contains/1.png'
import top2 from '../../contains/2.png'
import top3 from '../../contains/3.png'
import top4 from '../../contains/4.png'
import top5 from '../../contains/5.png'
const { Paragraph, Text } = Typography;
const { Meta } = Card;


export default class Demo extends Component{
    constructor(props) {
        super(props)
        this.state = { ellipsis: true }
      }
      setEllipsis = (isEllipsis) => {
        this.setState({ellipsis:isEllipsis});
      }
    render() {
        const ellipsis=this.state.ellipsis;
        return(
      <div>

      <Paragraph ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: 'more' } : false}>
          菲格勃姆(Feigenbaum)创立于2020年，是一家创新引领提升的战略管理咨询公司。 
      依托于同济大学汽车学院的科研力量和其影响力，菲格勃姆比任何其他的企业都更了解中国中小企业
      管理层和员工的行为和态度。根植于全面质量战略咨询、领导力提升和人事服务三大核心领域，菲格
      勃姆向领导层和企业提供深入分析和专业见解，以帮助他们解决最紧迫的问题。后疫情时代，
      菲格勃姆能够帮助您的企业更好的进行运营驱动增长。
      </Paragraph>
    
  <div className={styles["site-card-wrapper"]}>

    <Row justify="center">
      <Col >
        <Card bordered={false} hoverable type={'inner'} size={'small'}> <img alt="example" src={top1} /></Card>
      </Col>
      <Col>
      <Card bordered={false} hoverable type={'inner'} size={'small'}>
        <Row><img alt="example" src={top2} /></Row>
        <Row style={{margin:'10px 0px'}} ><img alt="1" src={top3} /></Row>
        
       </Card>
      </Col>
    </Row>
    <Row justify="center">
      <Col >
      <Card bordered={false} hoverable type={'inner'} size={'small'}><img alt="example" src={top4} /></Card>
      </Col>
      <Col >
      <Card bordered={false} hoverable type={'inner'} size={'small'}><img alt="example" src={top5} /></Card>
      </Col>
    </Row>
  </div>
          </div>
      )
    }
}