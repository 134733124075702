
import './App.css';
import React,{Component} from 'react';
import { Route,Switch,withRouter,Redirect,NavLink } from "react-router-dom";

import About from "./layouts/About/AboutUs";

import { Layout, Menu, Image } from 'antd';
import { HomeOutlined, AppstoreOutlined,AppstoreAddOutlined, ChromeOutlined } from '@ant-design/icons';
import Demo from './layouts/FirstPage/FirstPage'
import Product from './layouts/Product/Product'
import Connect from './layouts/Connect/Connect';
import Leader from './layouts/Server/Server'
import Consult from './layouts/Server/Consult'
import Hr from './layouts/Server/Hr'
import img from './contains/icon.png'
import styles from './style.module.css';
import { Link } from 'react-router-dom'
import { HashRouter } from 'react-router-dom';
import First from './layouts/First/First';
const { Header, Content, Footer } = Layout;

const App = withRouter(({ history }) => {
  console.log(history.location.pathname)
  return (
    <div>
    {/* <withRouter> */}
    <HashRouter>
          <Layout className={styles["layout"]}>
<Header >
<div className={styles["logo"]} />
<Menu mode="horizontal" defaultSelectedKeys={['/basicData']} selectedKeys={[history.location.pathname]}>
  <Menu.Item key="img">
  <Image
width={200} 
src={img}
preview={false}
/>
  </Menu.Item>
<Menu.Item key="/basicData" icon={<HomeOutlined />}>
<NavLink exact to='/basicData'>
                      <span className="nav-text">首页</span>
                    </NavLink>
</Menu.Item>
<Menu.Item key="/product" icon={<HomeOutlined />}>
<Link to={"/product"}>研究洞察</Link>
</Menu.Item>
<Menu.SubMenu key="/service" title="服务" icon={<ChromeOutlined />}>
<Menu.Item key="/service/qulity" icon={<AppstoreOutlined />}>
<Link to={"/service/qulity"}>全面质量战略咨询</Link>
</Menu.Item>
<Menu.Item key="/service/leader" icon={<AppstoreAddOutlined />}>
<Link to={"/service/leader"}>领导力提升</Link>
</Menu.Item>
<Menu.Item key="/service/hr" icon={<AppstoreAddOutlined />}>
<Link to={"/service/hr"}>人事服务</Link>
</Menu.Item>
</Menu.SubMenu>
<Menu.Item key="/about" icon={<HomeOutlined />}>
<Link to={"/about"}>关于我们</Link>
</Menu.Item>
<Menu.Item key="/connect" icon={<HomeOutlined />}>
<Link to={"/connect"}>联系我们</Link>
</Menu.Item>

</Menu>
</Header>
<Content style={{ padding: '0 50px' }}>
<div className={styles["site-layout-content"]} style={{ padding: 24, minHeight: 380 }}> 
<Switch>
                  <Redirect exact from='/' to='/basicData'></Redirect>
                  <Route exact path='/basicData' component={First}></Route>
                  <Route exact path='/about' component={About}></Route>
                  <Route exact path='/product' component={Product}></Route>
                  <Route exact path='/service/leader' component={Leader}></Route>
                  <Route exact path='/service/qulity' component={Consult}></Route>
                  <Route exact path='/service/hr' component={Hr}></Route>
                  <Route exact path='/connect' component={Connect}></Route>
                </Switch>
</div>
</Content>
<Footer style={{ textAlign: 'center' }}>© 2009-2022   | 关于Feigenbam | 联系我们  | 加入我们  |  隐私政策  |  <a href='https://beian.miit.gov.cn'>沪ICP备2022011675号</a></Footer>
</Layout>
</HashRouter>
{/* </withRouter> */}
</div>
)
})

export default App;

