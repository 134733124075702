import { Typography,Card } from 'antd';
import React, { Component} from 'react';
import styles from './style.module.css';
import black2 from '../../contains/black2.png'
const { Paragraph, Text,Title } = Typography;
const { Meta } = Card;
const message =  (
    <>
        在任何一个经营的时期，人们是否具备经营的意志力有着生死攸关的作用。在面对危机的时候，你的选择是什么，你的结果就会是什么。<br />
        在我持续的研究中，我常常发现，何以两家公司的外在环境相同，创立者的出身也类似，却在几年后有着全然不同的结果？何以环境对于一些企业来说极其重要，而对于另外一些企业来说只是经营的条件而已？<br />
        归根是领导者的经营意志力不同使然。<br />
        无论企业面对什么样的环境变化，企业最大的问题不是环境的变化，而是自我成长的问题，是从一种规模转变到另外一种规模的问题，是从一种发展模式转变到另外一种发展模式的问题。<br />
        而企业成长问题的实质就是管理态度的问题。企业如果要持续成功地成长，先决条件是管理者必须具有经营的意志力，必须能够不受环境的影响而保持为增长所采取的行动。<br />
        因而企业持续成长的真正问题在于管理者，在于管理者自身的意志力和解决问题的能力，以及自己创造增长的能力。<br />
        企业使命通常代表一系列相互依存的价值观念和行为方式的总和。这些价值观念、行为方式要为员工所共同拥有，需经过长时间的积累，需借助领导者行为的导向。<br />
        促进企业经营业绩增长的企业使命在开始的时候，至少有两点十分关键：<br />
        一是领导者必须拥有（或创立）与市场经营环境相适应的经营指导思想。<br />
        二是要有一个能够适应企业所处市场经营环境并能够带来经营成就的企业经营策略。<br />
        因此，领导者不仅要有能力带领企业获得有效的成长，当外在环境转变时，还必须能够将之安置并做进一步的发展。完成这样的要求，领导者必须具备：<br />
        觉知与洞悉能力<br />
        超凡的动机与坚持力<br />
        改变文化假定的能力<br />
        给人激励与希望的能力<br />
        如何驾驭不确定性已经成为管理者最核心的领导力挑战。不确定性已经深入到每一个角落，从气候的不确定性，到经济的不确定性；从组织的不确定性，到个体的不确定性，似乎没有人能够处在一个稳定的状态中。<br />
        这个时代的不确定性远远超过了以往任何时期，无论是在变化的规模、速度还是迅猛程度上，都与过去根本不在同一个数量级上。我们也必须学会驾驭这些不确定性，并创造出自己的生长模式。<br />
        具有经营意志力的领导者，在创造一个增长型公司的过程中所扮演的角色，一直是我研究的主题，优秀的领导者是保障公司持续增长的主导因素。具有经营意志力的领导者，就能够让企业和环境进行互动，就可以在洞察变化中做出选择。<br />
        所以，在讨论如何在危机中获得生机的时候，首要任务就是领导者有意愿、也有能力改变企业，改变管理者和员工的态度和行为，在危机的环境中，创造属于自己的生长方式。<br />
        在疫情面前，我们必须接受这一切，并与疫情共处。但是，我们也相信，无论在任何时候，信心总比黄金贵，人类意志都是强大而可依靠的力量。 <br />
      </>
 );

export default class Product extends Component{
    constructor(props) {
        super(props)
        this.state = { ellipsis: true }
      }
      setEllipsis = (isEllipsis) => {
        this.setState({ellipsis:isEllipsis});
      }
    render() {
        return(
      <div className={styles['backimage']}>
         <Typography>
    <Title style={{color:'black'}}>研究与洞察</Title>
    </Typography>
          <Card
    hoverable
    style={{ width: "auto" }}
    cover={<img alt="example" src={black2} />}
  > 
<Meta description={message}/>
  </Card>
          </div>
      )
    }
}